var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VSkeletonLoader',{attrs:{"grid-list-lg":"","fluid":"","type":"card","loading":(_vm.isFirstLoad && !_vm.isDashboard) || _vm.isLoading}},[_c('VRow',{attrs:{"row":"","wrap":""}},[(_vm.content.data.length === 0)?_c('VCol',{attrs:{"cols":"12"}},[_c('NoRecordCard',{attrs:{"searched-text":_vm.filter.s,"action-text":`Create a ${ _vm.featureName(_vm.tabName, 'singularize') }`,"title":`You don’t have any ${ _vm.featureName(_vm.tabName) } yet`,"description":`You can upload files in almost any format to make them readily available for ${ _vm.featureName('Students') } and/or ${ _vm.featureName('Mentors') }. Spreadsheets, PDFs, presentations, docs, and images—all a great way to keep your team and participants on the same page.`},on:{"clicked":function($event){return _vm.$emit('addSection', _vm.sections[_vm.activeTab])}}})],1):[(!_vm.isTilesView)?_c('VCol',{attrs:{"cols":"12"}},[_c('ContentIndex',{attrs:{"existing-data":_vm.content.data,"enable-infinite-loader":_vm.enableInfiniteLoader},on:{"duplicated":(result) => _vm.localPrimaryContents.data.unshift(result),"deleted":(id) =>
              (_vm.localPrimaryContents.data = _vm.localPrimaryContents.data.filter(
                (content) => content.id != id
              )),"updated":(result) =>
              (_vm.localPrimaryContents.data = _vm.localPrimaryContents.data.map((content) =>
                content.id === result.id ? result : content
              ))}})],1):_c('DataViewUI',{attrs:{"hide-actions":"","hide-main-title":"","hide-records-info":"","default-view-mode":"grid","background-color":"transparent","data":_vm.content,"md":_vm.colMd,"lg":_vm.colLg,"enable-infinite-loader":_vm.enableInfiniteLoader},on:{"infiniteLoad":_vm.onInfiniteLoad},scopedSlots:_vm._u([{key:"card",fn:function(props){return [_c('IndexCard',{attrs:{"hide-actions":"","height":"192px","background-color":"#fdbf14","visibility-icon-color":"#ffffff","avatar-color":"rgba(255, 255, 255, 0.2)","item":props.item,"item-type":_vm.tabName,"parent-model":_vm.model,"loading":props.item.loading || props.item.isDuplicating,"hide-menu":_vm.hideCardMenu(props.item),"count":(_vm.model.primary_contents_count - _vm.content.data.length) + 1,"show-count":_vm.checkShowCount(props.index),"image-card":_vm.getPreviewImage(props.item.files || []),"card-shadow":_vm.getPreviewImage(props.item.files || [])
                ? 'none'
                : '0 3px 8px 0 rgba(253, 191, 20, 0.5)',"alt-processing":props.item.processingDuplicate},on:{"onClick":function($event){return _vm.$emit('showViewDialog', _vm.tabName, props.item)},"duplicationDone":_vm.onDuplicationDone,"showMore":function($event){_vm.$emit('toggleTab', _vm.tabName.toLowerCase().pluralize())},"enableItemLoading":(status) => _vm.$set(_vm.localPrimaryContents.data[props.index], 'loading', status)}},[_c('template',{slot:"avatar"},[_c('img',{staticStyle:{"height":"20px","width":"20px"},attrs:{"src":require("../../../images/doc-icon.png")}})]),_c('template',{slot:"card-content"},[_c('VList',{attrs:{"color":"transparent"}},[_c('VListItem',{staticClass:"mt-n4 mx-n2"},[_c('VListItemContent',[_c('VListItemTitle',{staticClass:"section-card-title"},[_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(props.item.title)+" ")])])],1)],1),(props.item.content)?_c('VListItem',{staticClass:"mx-n2"},[_c('VListItemContent',{staticClass:"pt-0"},[_c('p',{directives:[{name:"linkified",rawName:"v-linkified"}],staticClass:"formatted content",style:({
                        'line-height': 1.4,
                        color: '#ffffff',
                        'font-size': '14px',
                      }),domProps:{"innerHTML":_vm._s(props.item.content)}})])],1):_vm._e()],1)],1)],2)]}}])})]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }