<script>
import SearchMixin from "@/mixins/Search";
export default {
  mixins: [SearchMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    sections: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isDashboard: {
      type: Boolean,
      default: false
    },
    defaultCommunityTab: {
      type: Number,
      default: 0
    },
    isTilesView: {
      type: Boolean,
      default: true
    },
    sortBy: {
      type: String,
      default: null
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    limitForDashboard: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      isLoading: false,
      getAllOnLoad: false,
    };
  },
  computed: {
    roles() {
      return [
        { title: "All" },
        { title: this.featureName("Mentors") },
        { title: this.featureName("Students") },
      ]
    },
    colLg() {
      if(this.$showLeftMainMenu) {
        return 4;
      }
      return this.isDashboard ? 4 : 3;
    },
    colMd() {
      if(this.$showLeftMainMenu) {
        return this.$isMenuExpanded ? 6 : 4;
      }
      return this.isDashboard ? 6 : 4;
    },
  },
  methods: {
    initSearch() {
      if(this.onGetAll instanceof Function) {
        this.doSearch(this.onGetAll);
      }
    },
    getPreviewImage(files) {
      if(files.length > 0) {
        let image = files.filter(file => {
          if(file.type.split("/")[0] === "image") {
            return file;
          }
        });
        return image.length > 0 ? image[0] : null;
      }
      return null;
    },
    hideCardMenu(item) {
      return (
        (!this.$isUserAdmin || !this.$viewProgramAsAdmin)
          && !(
              this.checkMentorPermission(this.tabName.toLowerCase(), this.model) 
                && this.belongsToMe(item.user_id)
              )
      );
    },
    isItemHidden(item) {
      let visibility = item.pivot.visible_to ? JSON.parse(item.pivot.visible_to) : [];
      if (Array.isArray(visibility) && visibility.length === 0) {
        return true;
      } else {
        let roles = Object.keys(visibility);
        return roles.length === 0 || (roles.length === 1 && roles[0] === "organization-admin");
      }
    },
    hasItemValidVisibility(item, _programId) {
      if(!item.hasOwnProperty("pivot") && _programId) {
        item.pivot = item.programs.find((program) => program.id === _programId).pivot;
      }
      if(!this.isItemHidden(item)) {
        let visibility = Object.keys(JSON.parse(item.pivot.visible_to));
        return visibility.includes(this.$isUserAdmin ? this.$store.state.program.viewAs : this.$user.role_slug);
      }
      return false;
    },
    updateLocalPendingDuplications(modelType, items) {
      const itemGroupName = "program-" + modelType;
      const pendingItems = this.getGroupItemLocalStorage("pending-duplications", itemGroupName);
      if(pendingItems) {
        const programPendingItems = Object.keys(pendingItems).filter((itemKey) => itemKey.split("-")[0] == this.model.id);
        programPendingItems.map((itemKey) => {
          const itemId = itemKey.split("-")[1];
          const realItemIndex = items.findIndex((tempItem) => tempItem.duplicated_using_id == itemId);
          const realModule = (modelType == "content" ? "primary_content" : modelType).pluralize();
          if(realItemIndex > -1) {
            if(!this.isDashboard) {
              const modelItemIndex = this.model[realModule].findIndex((tempItem) => tempItem.pivot.program_relations_id == itemId);
              this.model[realModule][modelItemIndex].id = items[realItemIndex].id;
              this.model[realModule][modelItemIndex].processingDuplicate = false;
            }
            this.removeGroupItemLocalStorage("pending-duplications", itemGroupName, itemKey);
          } else {
            items.unshift(pendingItems[itemKey]);
            if(!this.isDashboard) {
              this.model[realModule].unshift(pendingItems[itemKey]);
            }
          }
        });
      }
      return items;
    },
  },
}
</script>