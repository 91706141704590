<template>
  <VRow class="justify-center">
    <VCol cols="auto">
      <VCard 
        width="568px"
        class="form-card"
      >
        <VCardText>
          <h1 class="heading">
            Everything Your Team Needs to Scale Your Impact
          </h1>
          <h2 class="sub-heading py-2">
            WeThrive integrates your core tools into one central location that will scale your programs.
          </h2>
          <h3 class="auth-label">
            Create an account to start your 14 day free trial
          </h3>
          <VForm class="mt-2">
            <TextField
              v-model="form.team.name"
              hide-details
              placeholder="Company or Organization Name"
              :error="$v.form.team.name.$error"
              @blur="$v.$touch()"
              @input="$v.$touch()"
            />
            <TextField
              v-model="form.name"
              hide-details
              placeholder="Your Name"
              :error="$v.form.name.$error"
              @blur="$v.$touch()"
              @input="$v.$touch()"
            />
            <TextField
              v-model="form.email"
              type="email"
              placeholder="Email Address"
              :error="$v.form.email.$error"
              :hide-details="$v.form.email.email"
              :error-messages="$v.form.email.email ? [] : ['Invalid email']"
              @blur="$v.$touch()"
              @input="$v.$touch()"
            />
            <PasswordInput
              v-model="form.password"
              label=""
              placeholder="Password"
              :editable="false"
              :error="$v.form.password.$error"
              :hide-details="$v.form.password.minLength"
              :error-messages="$v.form.password.minLength ? [] : ['Password must be at least 6 characters']"
              @blur="$v.$touch()"
              @input="$v.$touch()"
            />
            <PasswordInput
              v-model="form.password_confirmation"
              label=""
              placeholder="Confirm Password"
              :editable="false"
              :error="$v.form.password_confirmation.$error"
              :hide-details="$v.form.password_confirmation.sameAsPassword || !$v.form.password_confirmation.required"
              :error-messages="$v.form.password_confirmation.sameAsPassword ? [] : ['Passwords do not match']"
              @blur="$v.$touch()"
              @input="$v.$touch()"
            />
            <VCheckbox
              v-model="form.marketing_opt_in"
              type="checkbox"
              hide-details
              class="mt-1 mb-4"
            >
              <template #label>
                <span style="font-size: 12px; color: #898989;">
                  I want to receive updates about WeThrive Hub
                </span>
              </template>
            </VCheckbox>
            <VBtn
              block
              color="primary"
              height="54px"
              class="black--text"
              style="border-radius: 9px; box-shadow: 0px 3px 6px 0px #00000033 !important;"
              :loading="isSaving"
              :disabled="$v.$invalid || $v.$error"
              @click.prevent="register"
            >
              <span style="font-size: 20px; font-weight: 600;">
                Start Your 14-Day Free Trial
              </span>
            </VBtn>
            <div 
              class="text-center mt-5"
              style="color: #808080;"
            >
              By creating an account, you accept our 
              <span
                class="link"
                @click="dialogs.terms = true"
              >
                Terms
              </span> & 
              <span
                class="link"
                @click="dialogs.privacy = true"
              >
                Privacy Policy
              </span>
            </div>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
    <VCol 
      cols="12"
      class="text-center"
      style="font-size: 18px; color: #808080;"
    >
      Already have an existing account? 
      <router-link 
        class="link" 
        :to="{ name: 'login' }"
      >
        Log In 
      </router-link>
    </VCol>
    <Dialog
      title="Privacy Policy"
      :is-dialog-open="dialogs.privacy"
      @toggle:dialog="dialogs.privacy = false"
    >
      <Privacy mode="dialog" />
    </Dialog>
    <Dialog
      title="Terms and Conditions"
      :is-dialog-open="dialogs.terms"
      @toggle:dialog="dialogs.terms = false"
    >
      <Terms mode="dialog" />
    </Dialog>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import { email, required, sameAs, minLength } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
import Terms from "@/components/App/Terms";
import Privacy from "@/components/App/Privacy";
import PasswordInput from "@/components/Elements/Forms/PasswordInput";

export default {
  name: "RegisterOrgForm",
  mixins: [FormMixin],
  components: { 
    Privacy,
    Terms,
    PasswordInput, 
  },
  data() {
    return {
      isSaving: false,
      dialogs: {
        privacy: false,
        terms: false,
      },
      form: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role: "owner",
        team: {
          name: "",
          slug: "",
        },
        terms: true,
        agreement: true,
        marketing_opt_in: true,
      },
    };
  },
  validations: {
    form: {
      team: {
        name: {
          required,
        },
      },
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    ...mapActions({
      doRegister: "register/team",
      doLogin: "auth/login",
    }),
    register() {
      this.isSaving = true;
      this.saveFormModel(this.doRegister, null, this.form, null, false)
        .then((response) => {
          if(response) {
            this.doLogin({
              username: this.form.email,
              password: this.form.password,
            }).then((result) => {
              if(result) {
                this.$emit("changeTab", 1);
                this.isSaving = false;
              }
            });
          }
        })
        .catch((error) => {
          this.isSaving = false;
          this.$bus.$emit("notificationError", this.parseErrors(error.message), true);
        });
    },
  },
};
</script>