<template>
  <AppLayout :enable-footer="false">
    <VContainer 
      fluid
      fill-height
    >
      <ConfirmPayment />
    </VContainer>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AlternateAppLayout";
import ConfirmPayment from "@/components/Account/Billing/ConfirmPayment";

export default {
  name: "PaymentConfirmation",
  components: {
    ConfirmPayment,
    AppLayout
  },
};
</script>