<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="card"
    :loading="(isFirstLoad && !isDashboard) || isLoading"
  >
    <VRow
      row
      wrap
    >
      <VCol
        v-if="content.data.length === 0"
        cols="12"
      >
        <NoRecordCard
          :searched-text="filter.s"
          :action-text="`Create a ${ featureName(tabName, 'singularize') }`"
          :title="`You don’t have any ${ featureName(tabName) } yet`"
          :description="`You can upload files in almost any format to make them readily available for ${ featureName('Students') } and/or ${ featureName('Mentors') }. Spreadsheets, PDFs, presentations, docs, and images—all a great way to keep your team and participants on the same page.`"
          @clicked="$emit('addSection', sections[activeTab])"
        />
      </VCol>
      <template v-else>
        <VCol
          v-if="!isTilesView"
          cols="12"
        >
          <ContentIndex
            :existing-data="content.data"
            :enable-infinite-loader="enableInfiniteLoader"
            @duplicated="(result) => localPrimaryContents.data.unshift(result)"
            @deleted="
              (id) =>
                (localPrimaryContents.data = localPrimaryContents.data.filter(
                  (content) => content.id != id
                ))
            "
            @updated="
              (result) =>
                (localPrimaryContents.data = localPrimaryContents.data.map((content) =>
                  content.id === result.id ? result : content
                ))
            "
          />
        </VCol>
        <DataViewUI
          v-else
          hide-actions
          hide-main-title
          hide-records-info
          default-view-mode="grid"
          background-color="transparent"
          :data="content"
          :md="colMd"
          :lg="colLg"
          :enable-infinite-loader="enableInfiniteLoader"
          @infiniteLoad="onInfiniteLoad"
        >
          <template
            slot="card"
            slot-scope="props"
          >
            <IndexCard
              hide-actions
              height="192px"
              background-color="#fdbf14"
              visibility-icon-color="#ffffff"
              avatar-color="rgba(255, 255, 255, 0.2)"
              :item="props.item"
              :item-type="tabName"
              :parent-model="model"
              :loading="props.item.loading || props.item.isDuplicating"
              :hide-menu="hideCardMenu(props.item)"
              :count="(model.primary_contents_count - content.data.length) + 1"
              :show-count="checkShowCount(props.index)"
              :image-card="getPreviewImage(props.item.files || [])"
              :card-shadow="
                getPreviewImage(props.item.files || [])
                  ? 'none'
                  : '0 3px 8px 0 rgba(253, 191, 20, 0.5)'
              "
              :alt-processing="props.item.processingDuplicate"
              @onClick="$emit('showViewDialog', tabName, props.item)"
              @duplicationDone="onDuplicationDone"
              @showMore="$emit('toggleTab', tabName.toLowerCase().pluralize())"
              @enableItemLoading="(status) => $set(localPrimaryContents.data[props.index], 'loading', status)"
            >
              <template slot="avatar">
                <img
                  src="../../../images/doc-icon.png"
                  style="height: 20px; width: 20px;"
                >
              </template>
              <template slot="card-content">
                <VList color="transparent">
                  <VListItem class="mt-n4 mx-n2">
                    <VListItemContent>
                      <VListItemTitle class="section-card-title">
                        <span class="text-truncate">
                          {{ props.item.title }}
                        </span>
                      </VListItemTitle>
                    </VListItemContent>
                  </VListItem>
                  <VListItem
                    v-if="props.item.content"
                    class="mx-n2"
                  >
                    <VListItemContent class="pt-0">
                      <p
                        v-linkified
                        v-html="props.item.content"
                        class="formatted content"
                        :style="{
                          'line-height': 1.4,
                          color: '#ffffff',
                          'font-size': '14px',
                        }"
                      />
                    </VListItemContent>
                  </VListItem>
                </VList>
              </template>
            </IndexCard>
          </template>
        </DataViewUI>
      </template>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import ViewProgramSection from "@/mixins/ViewProgramSection";
import ContentIndex from "@/components/Content/";
import IndexCard from "../Card";
import NoRecordCard from "./NoRecordCard";
export default {
  name: "Contents",
  mixins: [ViewProgramSection],
  components: {
    IndexCard,
    ContentIndex,
    NoRecordCard,
  },
  data() {
    return {
      tabName: "Content",
      localPrimaryContents: {
        data: [],
      },
    };
  },
  watch: {
    activeTab: {
      handler: function(value) {
        if(this.sections[value].name == "contents") {
          this.filter.s = "";
          this.initSearch();
        } else {
          this.localPrimaryContents.data = this.model.primary_contents;
        }
        this.enableInfiniteLoader = this.sections[value].name == "contents";
      },
      immediate: true,
    },
    model: {
      handler: function(value) {
        this.localPrimaryContents.data = value.primary_contents;
      },
      deep: true,
    },
    infiniteLoad: {
      handler: function(value) {
        if(!this.isDashboard) {
          this.localPrimaryContents = value;
        }
      },
      deep: true,
    },
  },
  computed: {
    content() {
      const availableContents = this.$isUserAdmin && this.$viewProgramAsAdmin 
        ? this.localPrimaryContents 
        : {
            ...this.localPrimaryContents,
            data: this.localPrimaryContents.data.filter((content) => this.hasItemValidVisibility(content, this.model.id) || this.belongsToMe(content.user_id)),
          };
      return availableContents;
    },
  },
  methods: {
    ...mapActions({
      doGetContents: "content/getAll",
    }),
    onGetAll(params) {
      params.perPage = 12;
      params.filter = {
        program: [this.model.id],
        strict: !this.filter.s,
      };
      params.s = this.filter.s;
      return this.doGetContents(params).then((result) => {
        result.data = this.updateLocalPendingDuplications("content", result.data);
        if(this.isDashboard) {
          this.localPrimaryContents = result;
        }
        return result;
      });
    },
    onUpdated(result) {
      this.localPrimaryContents.data = this.localPrimaryContents.data.map((content) => {
        return content.id === result.id ? result : content;
      });
    },
    checkShowCount(key) {
      if(this.isDashboard) {
        return this.model.primary_contents_count > this.limitForDashboard && key === this.content.data.length - 1;
      }
      return false;
    },
    onDuplicationDone(result) {
      this.localPrimaryContents.data.unshift(result);
      if(!this.isDashboard) {
        this.model.primary_contents.unshift(result);
      }
    },
  },
};
</script>
