var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',[_c('VCol',{attrs:{"cols":"12"}},[_c('img',{attrs:{"height":"70px","src":_vm.WeThriveLogo}})]),_c('VCol',{attrs:{"cols":"12"}},[_c('div',{style:({
        background: `url(${_vm.ProgressImage})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        height: '36px',
        width: '320px',
      })})]),_c('VCol',{attrs:{"cols":"12"}},[_c('h2',{staticStyle:{"font-size":"28px"}},[_vm._v(" Your payment is processing. ")])]),_c('VCol',{staticClass:"text-center py-0",attrs:{"cols":"12"}},[_c('p',{staticStyle:{"color":"#808080","font-size":"20px","width":"488px"}},[_vm._v(" We’ve received your payment and now we’re just passing info back and forth to our payment processor. You should be redirected to your workspace any minute. If not, you can click the link below once available. ")])]),_c('VCol',{staticClass:"py-0",attrs:{"cols":"12"}},[(_vm.enableRedirectLink)?_c('router-link',{staticClass:"text-body-2 text-decoration-underline",staticStyle:{"color":"#333333"},attrs:{"to":{
        name: 'program.index',
      }}},[_vm._v(" Let’s wrap this up! ")]):_c('VProgressCircular',{attrs:{"color":"primary","indeterminate":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }