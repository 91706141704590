<template>
  <VRow>
    <VCol cols="12">
      <img
        height="70px"
        :src="WeThriveLogo"
      >
    </VCol>
    <VCol cols="12">
      <div
        :style="{
          background: `url(${ProgressImage})`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          height: '36px',
          width: '320px',
        }"
      />
    </VCol>
    <VCol cols="12">
      <h2 style="font-size: 28px;">
        Your payment is processing.
      </h2>
    </VCol>
    <VCol 
      cols="12" 
      class="text-center py-0"
    >
      <p style="color: #808080; font-size: 20px; width: 488px;">
        We’ve received your payment and now we’re just passing info back and forth to our payment processor. You should be redirected to your workspace any minute. If not, you can click the link below once available.
      </p>
    </VCol>
    <VCol
      cols="12" 
      class="py-0"
    >
      <router-link 
        v-if="enableRedirectLink"
        class="text-body-2 text-decoration-underline"
        style="color: #333333;"
        :to="{
          name: 'program.index',
        }"
      >
        Let’s wrap this up!
      </router-link>
      <VProgressCircular
        v-else
        color="primary"
        indeterminate
      />
    </VCol>
  </VRow>
</template>
<script>
import { mapActions } from "vuex";
import ProgressImage from "@/images/progress-bar.gif";
import WeThriveLogo from "@/images/logo_wethrivehub.png";
export default {
  name: "ConfirmPayment",
  data() {
    return {
      ProgressImage,
      WeThriveLogo,
      setIntervalId: null,
      enableRedirectLink: false,
    }
  },
  created() {
    if(this.$route.query.hasOwnProperty("billing_status") && this.$route.query.hasOwnProperty("session_id")) {
      if(this.$team.isValidSubscription && this.$team.subscription_plan) {
        this.$router.push({
          name: "program.index",
          params: {
            billing_status: "success",
            team: this.$team.slug,
          },
        });
      } else {
        this.setIntervalId = setInterval(() => this.onGetTeam(), 10000);
      }
    } else {
      this.$router.push({
        name: "program.index",
        params: {
          team: this.$team.slug,
        },
      });
    }
  },
  methods: {
    ...mapActions({
      getMe: "user/me",
      setMe: "user/setMe",
    }),
    onGetTeam() {
      this.getMe().then((result) => {
        if(
          result.team.isValidSubscription && 
          result.team.subscription_plan.hasOwnProperty("product") && 
          result.team.subscription_plan.product
        ) {
          this.setMe(result);
          this.$team = result.team;
          this.enableRedirectLink = true;
          clearInterval(this.setIntervalId);
          this.$router.push({
            name: "program.index",
            params: {
              billing_status: "success",
              team: result.team.slug,
            },
          });
        }
      });
    },
  },
}
</script>
<style lang="scss" scoped>
.col {
  display: flex;
  justify-content: center;
}
</style>