<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading,card"
    :loading="activity.isLoading"
  >
    <VRow id="billing">
      <VCol cols="12">
        <h1
          class="font-weight-black text-center"
          style="font-size: 36px;"
        >
          Select Your Plan
        </h1>
        <h2
          class="text-h6 font-weight-regular text-center"
          style="color: #808080; line-height: 27px; margin: 8px 124px;"
        >
          WeThrive offers plans to help organizations of all shapes and sizes. Choose the right one for your org—you can always upgrade or downgrade later.
        </h2>
        <VRow
          justify-center
          class="text-center py-6"
        >
          <VCol
            cols="12"
            class="font-weight-bold"
            style="font-size: 15px;"
          >
            Renewal Cycle
          </VCol>
          <VCol 
            cols="auto"
            style="position: relative;"
          >
            <VTabs
              v-model="activeTab"
              centered
              height="50px"
              active-class="rounded-pill white--text info"
              class="rounded-pill my-4"
              style="border: 2px solid #2296F0;"
            >
              <VTab>
                Annual
              </VTab>
              <VTab>
                Monthly
              </VTab>
            </VTabs> 
            <img 
              v-if="activeTab == 1"
              style="position: absolute; top: 28px; left: -4px;"
              :src="require('../../../images/curved-arrow.png')"
            >
          </VCol> 
        </VRow>
        <p 
          v-if="activeTab == 1"
          class="mt-n8 text-center"
          style="font-size: 17px; color: #4F4F4F;"
        >
          Get two months free with annual plans
        </p>
        <VRow>
          <VCol>
            <VCard
              class="pa-5"
              style="border-radius: 20px;"
            >
              <VCardText>
                <h1 
                  class="font-weight-black"
                  style="font-size: 28px;"
                >
                  {{ plan.title }}
                </h1>
                <h3
                  class="text-body-1 py-4" 
                  style="color: #4F4F4F; line-height: 18px;"
                >
                  Everything you need to run programs efficiently and scale.
                </h3>
                <div class="text-h6 font-weight-regular">
                  <span class="text-h4 font-weight-black d-inline-block">
                    ${{ pricePerAdmin }}
                  </span>/month/Admin
                </div>
                <h6
                  class="text-body-1" 
                  style="color: #8B8B8B;"
                >
                  billed {{ activeTab ? "monthly" : "annually" }}
                </h6>
                <div class="py-4">
                  <p
                    v-for="(feature, index) in programEssentials"
                    class="text-subtitle-1 mb-2"
                    :key="index"
                  >
                    <VIcon 
                      color="info" 
                      size="20px"
                      class="pr-2 mb-1"
                    >
                    mdi-check
                    </VIcon>
                    {{ feature }}
                  </p>
                </div>
                <VCard
                  v-if="adminsCount > 0"
                  flat
                  color="#F4F2F8"
                  class="px-4 mb-4"
                  style="padding: 10px;"
                >
                  <h2
                    class="font-weight-black py-2"
                    style="font-size: 21px;"
                  >
                    Admin Licenses
                  </h2>
                  <p 
                    class="text-body-1"
                    style="color: #4F4F4F; line-height: 18px;"
                  >
                    You currently have {{ NumberToWords.toWords(adminsCount) }} ({{ adminsCount }}) admins on your Team. To change your number of licenses, 
                    <span 
                      class="font-weight-bold d-inline-block link"
                      style="color: #2196f3 !important; font-weight: 700 !important;"
                      @click="dialogs.manageAdmins = true"
                    >
                      click here
                    </span>.
                  </p>
                  <VRow>
                    <VCol
                      class="d-flex align-center"
                      style="font-size: 18px;"
                    >
                      Subtotal
                    </VCol>
                    <VCol 
                      class="text-h6 font-weight-regular"
                      style="line-height: 23px;"
                    >
                      ..........................
                    </VCol>
                    <VCol class="text-right">
                      <span 
                        class="text-h6 font-weight-regular d-inline-block"
                        style="line-height: 26px;"
                      >
                        ${{ subtotal }}
                      </span>
                      <span style="font-size: 18px; line-height: 23px;">/{{ activeTab ? "mo." : "yr" }}</span>
                    </VCol>
                  </VRow>
                </VCard>
                <VBtn 
                  block
                  color="primary"
                  height="54px"
                  style="border-radius: 9px; box-shadow: 0px 3px 6px 0px #00000033 !important;"
                  :loading="isCheckoutLoading"
                  :disabled="isCheckoutLoading || isCustomLoading"
                  @click="initiateCheckout"
                >
                  <span 
                    class="text-subtitle-1 font-weight-bold"
                    style="color: #333333;"
                  >
                    Get the Essentials
                  </span>
                </VBtn>
              </VCardText>
            </VCard>
          </VCol>
          <VCol>
            <VCard
              class="pa-5"
              style="border-radius: 20px;"
            >
              <VCardText>
                <h1
                  class="font-weight-black"
                  style="font-size: 28px;"
                >
                  Enterprise Value
                </h1>
                <h3
                  class="text-body-1 py-4"
                  style="color: #4F4F4F; line-height: 18px;"
                >
                  Everything on your own branded mobile apps with a dedicated Impact Strategist.
                </h3>
                <h4
                  class="font-weight-bold py-2"
                  style="font-size: 22px;"
                >
                  Contact Sales for Pricing
                </h4>
                <div class="py-4">
                  <p
                    v-for="(feature, index) in enterpriseValue"
                    class="text-subtitle-1 mb-2"
                    :key="index"
                  >
                    <VIcon 
                      color="info" 
                      size="20px"
                      class="pr-2 mb-1"
                    >
                    mdi-check
                    </VIcon>
                    {{ feature }}
                  </p>
                </div>
                <VBtn 
                  block
                  color="primary"
                  height="54px"
                  style="border-radius: 9px; box-shadow: 0px 3px 6px 0px #00000033 !important;"
                  :loading="isCustomLoading"
                  :disabled="isCustomLoading || isCheckoutLoading"
                  @click="requestCustomQuote"
                >
                  <span
                    class="text-subtitle-1 font-weight-bold"
                    style="color: #333333;"
                  >
                    Request a Custom Quote
                  </span>
                </VBtn>
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
      </VCol>
      <Dialog
        max-width="708px"
        card-text-class="my-4"
        :dismissable="false"
        :is-dialog-open="dialogs.manageAdmins"
        @toggle:dialog="dialogs.manageAdmins = !dialogs.manageAdmins"
      >
        <Admins 
          :is-monthly="activeTab > 0"
          :price="pricePerAdmin"
          :plan-title="plan.title"
          @save="onSave" 
          @toggle:dialog="dialogs.manageAdmins = !dialogs.manageAdmins"
        />
      </Dialog>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import NumberToWords from "number-to-words";
import Admins from "./Admins";
export default { 
  components: { Admins },
  data() { 
    return {
      NumberToWords,
      activeTab: 0,
      adminsCount: 0,
      activeAdminsIds: [],
      removedAdminsIds: [],
      isCheckoutLoading: false,
      isCustomLoading: false,
      plan: {
        title: null,
        price: 0,
        price_yearly: 0,
      },
      activity: {
        isLoading: true,
      },
      dialogs: {
        manageAdmins: false,
      },
      programEssentials: [
        "Central Hub for participants and stakeholders",
        "Participant profiles",
        "Survey and data collection",
        "Application management and tracking",
        "Intake and registration",
        "Content library and storage",
        "Unlimited courses",
        "Quizzes and assessments",
        "Impact tracking and analytics",
        "Chat and messaging",
        "Unlimited program participants and facilitators",
      ],
      enterpriseValue: [
        "Everything in Program Essentials",
        "Your own iOS & Android apps",
        "Branded app notifications",
        "Migration and launch services",
        "Dedicated Impact Strategist",
        "Custom Reporting",
      ],
    };
  },
  computed: {
    pricePerAdmin() {
      return this.activeTab ? this.plan.price : this.plan.price_yearly / 12;
    },
    totalPricePerMonth() {
      return this.pricePerAdmin * this.adminsCount;
    },
    subtotal() {
      if(this.activeTab) {
        return this.totalPricePerMonth;
      }
      return this.totalPricePerMonth * 12;
    },
  },
  created() {
    const currentTeam = this.$auth.user().team;
    if(!currentTeam.is_free && currentTeam.trialEnded && !currentTeam.isValidSubscription) {
      this.setMe(this.$auth.user());
      this.getBillingPlans();
    } else {
      this.$router.push({
        name: "program.index",
        params: {
          team: currentTeam.slug,
        },
      });
    }
    localStorage.removeItem("remove-admin");
  },
  mounted() {
    if(this.$route.query.hasOwnProperty("cancel") && this.$route.query.cancel) {
      this.$bus.$emit("notificationError", "It looks like your payment wasn’t completed. Please try again.", true);
    }
  },
  methods: {
    ...mapActions({
      doGetAdminUsers: "users/getAll",
      setMe: "user/setMe",
      doCheckout: "payment/checkout",
      doGetBillingPlans: "payment/getPlans",
      doRequestCustomQuote: "payment/customQuote",
    }),
    getBillingPlans() {
      this.doGetBillingPlans().then((result) => {
        // Currently, we are going for single plan only. So some static information are used on the FE.
        this.plan = result[0];
        this.getAdminUsers();
      });
    },
    getAdminUsers() {
      const params = {
        perPage: 100, // To get all the admin users
        filter: {
          'team_user.role': ['organization-admin'],
        },
      };
      this.doGetAdminUsers(params).then((result) => {
        this.activeAdminsIds = result.data.map((item) => item.id);
        // Total admins plus a team owner
        this.adminsCount = result.total + 1;
        this.activity.isLoading = false;
      });
    },
    onSave(removedAdminsIds, remainingAdminsCount) {
      this.removedAdminsIds = removedAdminsIds;
      this.adminsCount = remainingAdminsCount || 1;
      this.dialogs.manageAdmins = !this.dialogs.manageAdmins;
    },
    initiateCheckout() {
      this.isCheckoutLoading = true;
      const params = {
        product_id: this.plan.id,
        interval: this.activeTab ? "month" : "year",
        admins: this.activeAdminsIds.filter((id) => this.removedAdminsIds.indexOf(id) === -1),
      };
      this.doCheckout(params).then((result) => {
        if(result.url) {
          localStorage.setItem("temp_plan", this.plan.title);
          window.location.href = result.url;
        }
      });
    },
    requestCustomQuote() {
      this.isCustomLoading = true;
      this.doRequestCustomQuote().then((result) => {
        if(result) {
          this.$router.push({
            name: 'billing.enterpriseConfirmation',
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
#billing {
  .v-tabs-bar {
    padding: 7px 8px !important;
  }
  .v-tabs-bar__content {
    padding: 0px !important;
    margin-top: 0px !important;
    background-color: transparent !important;
    :not(.v-tab--active) {
      color: #2296F0 !important;
    }
  }
  .v-tab {
    font-size: 15px;
    height: 36px !important;
    min-width: 84px !important;
  }
}
</style>